/* CSS reset  */
/*******************************************************************************************************************************************/
@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700,800');
/*@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');


/*******************************************************************************************************************************************/

/*******************************************************************************************************************************************/



/* Reset */
* {
	outline: 0 !important;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
blockquote,
pre,
code,
address,
ul,
ol,
li,
menu,
nav,
section,
article,
aside,
dl,
dt,
dd,
table,
thead,
tbody,
tfoot,
label,
caption,
th,
td,
form,
fieldset,
legend,
hr,
input,
button,
textarea,
object,
figure,
figcaption {
	font-family: 'nanumsquare', 'noto sans kr';
	margin: 0;
	padding: 0;
}

html {
	overflow-y: scroll;
}

body {
	width: 100%;
}

html {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	width: 100%;
	background: #fff;
	min-width: 320px;
	-webkit-text-size-adjust: none;
	word-wrap: break-word;
	word-break: keep-all;
}

body,
input,
select,
textarea,
button {
	border: none;
	font-family: 'nanumsquare', 'Noto Sans KR', sans-serif, "roboto", "Open Sans", 'Montserrat', sans-serif;
	color: #222;
}

ul,
ol,
li {
	list-style: none;
}

table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
}

img,
fieldset {
	border: 0;
}

address,
cite,
code,
em {
	font-style: normal;
	font-weight: normal;
}

label,
img,
input,
select,
textarea,
button {
	vertical-align: middle;
}

.hide,
caption,
legend {
	line-height: 0;
	font-size: 1px;
	overflow: hidden;
}

hr {
	display: none;
}

main,
header,
section,
nav,
footer,
aside,
article,
figure {
	display: block;
}

a {
	color: #000;
	text-decoration: none;
}


.clear {
	clear: both;
}

.clear:after {
	content: "";
	display: block;
	clear: both;
}



input:focus {
	border: 0;
	outline: 0;
	outline: none
}

.underline {
	text-decoration: underline
}

.mar_t10 {
	margin-top: 10px;
}

.mar_t20 {
	margin-top: 20px;
}

.mar_t30 {
	margin-top: 30px;
}

.mar_t40 {
	margin-top: 40px;
}

.mar_t50 {
	margin-top: 50px;
}

.mar_t60 {
	margin-top: 60px;
}

.mar_t70 {
	margin-top: 70px;
}

.mar_t80 {
	margin-top: 80px;
}

.mar_t90 {
	margin-top: 90px;
}


.mar_l10 {
	margin-left: 10px;
}

.mar_l20 {
	margin-left: 20px;
}

.mar_l30 {
	margin-left: 30px;
}

.mar_l40 {
	margin-left: 40px;
}

.mar_l50 {
	margin-left: 50px;
}

.mar_l60 {
	margin-left: 60px;
}

.mar_l70 {
	margin-left: 70px;
}

.mar_l80 {
	margin-left: 80px;
}

.mar_l90 {
	margin-left: 90px;
}

.mar_r10 {
	margin-right: 10px;
}

.mar_r20 {
	margin-right: 20px;
}

.mar_r30 {
	margin-right: 30px;
}

.mar_r40 {
	margin-right: 40px;
}

.mar_r50 {
	margin-right: 50px;
}

.mar_r60 {
	margin-right: 60px;
}

.mar_r70 {
	margin-right: 70px;
}

.mar_r80 {
	margin-right: 80px;
}

.mar_r90 {
	margin-right: 90px;
}


.mar_b10 {
	margin-bottom: 10px;
}

.mar_b20 {
	margin-bottom: 20px;
}

.mar_b30 {
	margin-bottom: 30px;
}

.mar_b40 {
	margin-bottom: 40px;
}

.mar_b50 {
	margin-bottom: 50px;
}

.mar_b60 {
	margin-bottom: 60px;
}

.mar_b70 {
	margin-bottom: 70px;
}

.mar_b80 {
	margin-bottom: 80px;
}

.mar_b90 {
	margin-bottom: 90px;
}


.fff {
	color: #fff;
}