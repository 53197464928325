.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  /* background-color: rgba(0, 0, 0, 0.6); */
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  /* max-width: 450px; */
  /* border-radius: 0.3rem; */
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modal > section > header {
  position: relative;
  padding: 6px 64px 6px 16px;
  background-color: #f1f1f1;
  font-weight: 700;
}
.modal > section > header button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modal > section > main {
  padding: 0px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  border-radius: 5px;
}

.modal > section > main > img{
  width: 100%;
  height: 100%;
}

.modal > section > footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  text-align: right;
  border: 1px solid #000;
}

.modal > section > footer span {
  padding: 6px 12px;
  color: #000;
  font-size: 13px;
  cursor: pointer;
}

.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  width: 610px;
  height: 600px;
  margin: 200px 0 0 50px;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 1;
    margin-top: -50px;
  }
  to {
    opacity: 0;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width:1180px) {
  .modal.openModal {
    display: flex;
    width: 400px;
    height: 500px;
    margin: 100px 0 0 10px;
    align-items: center;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
  }

  .modal > section > a > main > img{
    width: 100%;
    height: 100%;
  }
  
}