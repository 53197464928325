@media screen and (max-width:1180px) {
	#header {
		widtH: 100%;
		padding: 0.7em 3%;
		box-sizing: border-box;
		display: inline-block;
		position: absolute;
		z-index: 1;
	}

	#header .logo {
		width: 105px;
		display: inline-block;
		vertical-align: middle;
	}

	#header .logo .logo_w {
		display: block;
	}

	#header .logo .logo_c {
		display: none;
	}

	#header .logo img {
		width: 100%;
	}

	#header .menu {
		display: inline-block;
		vertical-align: middle;
		display: none;
	}

	#header .menu li {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		color: #fff;
		margin-left: 80px;
	}

	#header .menu li a {
		color: #fff;
	}

	#header .rtMenu {
		display: inline-block;
		float: right;
		margin-top: 25px;
	}

	#header .rtMenu .cafe {
		display: inline-block;
		vertical-align: middle;
	}

	#header .rtMenu .cafe .ico {
		display: inline-block;
		vertical-align: middle;
		width: 25px;
	}

	#header .rtMenu .cafe .ico img {
		width: 100%;
	}

	#header .rtMenu .cafe .text {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		color: #fff;
		font-family: 'lato';
		margin-left: 5px;
		display: none;
	}

	#header .rtMenu .allMenu {
		display: inline-block;
		vertical-align: middle;
		margin-top: 0px;
		display: none;
	}

	#header .rtMenu .moMenu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 1em;
		width: 25px;
	}

	#header .rtMenu .moMenu img {
		width: 100%;
	}



	#header .rtMenu .menu_c {
		display: none;
	}



	#header .dropMenu {
		display: none;
		padding: 50px 0px;
		box-sizing: border-box;
		width: 100%;

	}

	#header .dropMenu .titArea {
		width: 13%;
		display: inline-block;
		vertical-align: top;
	}

	#header .dropMenu .titArea .tit {
		font-size: 30px;
		color: #222;
		font-weight: bold;
	}

	#header .dropMenu .titArea .subTit {
		font-size: 20px;
		color: #777;
		margin-top: 15px;
		word-break: keep-all;
	}

	#header .dropMenu .menuCont {
		width: 52%;
		display: inline-block;
		vertical-align: top;
	}

	#header .dropMenu .menuCont .menuList {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
	}


	#header .dropMenu .menuCont .menuList dd {
		width: 32%;
		/* width:24%; */
		display: inline-block;
		padding: 0px 10px;
		box-sizing: border-box;
		vertical-align: top;
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box {
		width: 100%;
		border: 1px solid #e5e5e5;
		padding: 17px 15px 15px;
		display: Inline-block;
		border-radius: 5px;
		box-sizing: border-box;
		display: Inline-block;
		font-size: 16px;
		font-weight: bold;
		color: #222;
		background: rgba(0, 79, 134, 0);
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box span {
		display: inline-block;
		float: right;
		width: 25px;
		margin-top: -3px;
		opacity: 0;
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box span img {
		width: 100%;
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box:hover {
		background: rgba(0, 79, 134, 1);
		transition: all 1s;
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box:hover a {
		color: #fff;
	}

	#header .dropMenu .menuCont .menuList dd .depth01Box:hover span {
		opacity: 1;
	}

	#header .dropMenu .menuCont .menuList dd .depth02List {
		width: 100%;
		padding-left: 20px;
		box-sizing: border-box;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	#header .dropMenu .menuCont .menuList dd .depth02List li {
		width: 100%;
		margin-top: 12px;
		list-style: disc;
		color: #777;
		font-size: 15px;
	}

	#header .dropMenu .menuCont .menuList dd .depth02List li a {
		color: #777
	}

	#header .dropMenu .menuCont .menuList dd .depth02List li:hover a {
		color: rgba(0, 79, 134, 1);
		/* font-weight:bold; */
	}

	#header .dropMenu .dropmenuBG {
		width: 34%;
		display: inline-block;
		vertical-align: top;
	}

	#header .dropMenu .dropmenuBG img {
		width: 100%;
	}





	#header .moSlideMenu {
		width: 100%;
		position: fixed;
		background: #fff;
		z-index: 9999;
		height: 100%;
		top: 0;
		overflow-y: scroll;
		right: -100%;
		transition: all .3s;
		display: block;
	}





	#header .moSlideMenu .topLine {
		width: 100%;
		padding: 10px 3%;
		border-bottom: 1px solid #222;
		display: inline-block;
		box-sizing: border-box;
	}

	#header .moSlideMenu .topLine .logo {
		width: 80px;
		display: inline-block;
	}

	#header .moSlideMenu .topLine .logo img {
		width: 100%;
	}

	#header .moSlideMenu .menuClose {
		width: 30px;
		line-height: 30px;
		background: #e5e5e5;
		text-align: center;
		border-radius: 100%;
		display: inline-block;
		float: right;
		padding: 0px 10px;
		box-sizing: border-box;

		display: flex;
		align-items: center;
		height: 30px;
	}

	#header .moSlideMenu .menuClose img {
		width: 100%;
	}

	#header .moSlideMenu .menuList {
		width: 100%;
	}

	#header .moSlideMenu .menuList dd a.active {
		width: 100%;
		padding: 25px 3%;
		box-sizing: border-box;
		border-bottom: 1px solid #e5e5e5;
		font-size: 15px;
		display: inline-block;
		background: rgba(0, 79, 134, 1);
		color: white;
	}


	#header .moSlideMenu .menuList dd a {
		width: 100%;
		padding: 25px 3%;
		box-sizing: border-box;
		border-bottom: 1px solid #e5e5e5;
		font-size: 15px;
		display: inline-block;
	}

	#header .moSlideMenu .menuList dd .text {
		display: inline-block;
	}

	#header .moSlideMenu .menuList dd .icoArea {
		display: inline-block;
		float: right;
		width: 12px;
	}

	#header .moSlideMenu .menuList dd .toggleClass {
		transform: rotate(-180deg)
	}

	#header .moSlideMenu .menuList dd .mm li {
		background: #f7f7f7;
		display: inline-block;
		width: 100%;
	}

	#header .moSlideMenu .menuList dd .mm li span {
		background: #f7f7f7;
		float: right;
		opacity: 0.3;
	}

	#header .moSlideMenu .menuList dd .mm li a {
		padding: 25px 3% 25px 6%;
	}

	#header .moSlideMenu .menuList dd .mm li li a {
		padding: 25px 3% 25px 9%;
		background: #fff;
		color: #777;
	}

	#header .moSlideMenu .menuList dd .mm li li:first-child a {
		border-top: 1px solid #222;
	}

	#header .moSlideMenu .menuList dd .mm li li:last-child a {
		border-bottom: 1px solid #999;
	}




	#header .moSlideMenu::-webkit-scrollbar {
		width: 3px;
	}

	#header .moSlideMenu::-webkit-scrollbar-track {
		background-color: #f7f7f7;
	}

	#header .moSlideMenu::-webkit-scrollbar-thumb {
		background: #e5e5e5;
	}

	#header .moSlideMenu::-webkit-scrollbar-button {
		display: none;
	}




	/*subpage header*/

	.subWrap #header {
		widtH: 100%;
		padding: 0.7em 3%;
		box-sizing: border-box;
		display: inline-block;
		position: relative;
		z-index: 2;
		background: #fff;
	}

	.subWrap #header .logo {
		width: 105px;
		display: inline-block;
		vertical-align: middle;
	}

	.subWrap #header .logo .logo_w {
		display: none;
	}

	.subWrap #header .logo .logo_c {
		display: block;
	}

	.subWrap #header .logo img {
		width: 100%;
	}

	.subWrap #header .menu {
		display: inline-block;
		vertical-align: middle;
		display: none;
	}

	.subWrap #header .menu li {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		color: #fff;
		margin-left: 80px;
	}

	.subWrap #header .menu li a {
		color: #222;
	}

	.subWrap #header .menu li a.active {
		color: rgba(0, 79, 134, 1) !important;
	}

	.subWrap #header .rtMenu {
		display: inline-block;
		float: right;
		margin-top: 27px;
	}


	.subWrap #header .rtMenu .allMenu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 0px;
		cursor: pointer;
		display: none;
	}

	.subWrap #header .rtMenu .moMenu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 0px;
		width: 25px;
	}


	.subWrap #header .rtMenu .moMenu img {
		width: 100%;
	}

	.subWrap #header .rtMenu .menu_c {
		display: block;
	}

	.subWrap #header .rtMenu .menu_w {
		display: none;
	}




	.subWrap #header .dropMenu {
		display: none;
		padding: 50px 50px;
		box-sizing: border-box;
		width: 100%;
		position: absolute;
		background: #fff;
		left: 0;
	}











	#footer {
		width: 100%;
		background: #333333;
		padding: 3EM 0px;
	}

	#footer .inner {
		width: 94%;
		margin: 0 auto;
	}

	#footer .inner .topLine {
		width: 100%;
		display: inline-block;
	}

	#footer .inner .topLine .footLogo {
		width: 438px;
		display: inline-block;
	}

	#footer .inner .topLine .footLogo img {
		width: 100%;
	}

	#footer .inner .topLine .snsList {
		display: inline-block;
		float: right;
	}

	#footer .inner .topLine .snsList li {
		width: 24px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
	}


	#footer .inner .footMenu {
		width: 100%;
		margin-top: 40px;
	}

	#footer .inner .footMenu li {
		display: inline-block;
		padding: 0px 10px;
		border-right: 1px solid #777;
		color: #777;
		font-size: 15px;
		line-height: 15px;
	}

	#footer .inner .footMenu li a {
		color: #777;
	}

	#footer .inner .footMenu li:last-child {
		border-right: 0px solid #777;
	}

	#footer .inner .footMenu li:first-child {
		padding: 0px 10px 0px 0px;
	}

	#footer .inner .copy {
		width: 100%;
		margin-top: 20px;
		font-size: 14px;
		color: #666666;
		line-height: 22px;
	}







}










@media screen and (max-width:767px) {
	#header {
		widtH: 100%;
		padding: 0.5em 3%;
		box-sizing: border-box;
		display: inline-block;
		position: absolute;
		z-index: 1;
	}

	#header .logo {
		width: 90px;
		display: inline-block;
		vertical-align: middle;
	}

	#header .logo .logo_w {
		display: block;
	}

	#header .logo .logo_c {
		display: none;
	}

	#header .logo img {
		width: 100%;
	}

	#header .menu {
		display: inline-block;
		vertical-align: middle;
		display: none;
	}

	#header .menu li {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		color: #fff;
		margin-left: 80px;
	}

	#header .menu li a {
		color: #fff;
	}

	#header .rtMenu {
		display: inline-block;
		float: right;
		margin-top: 20px;
	}

	#header .rtMenu .cafe {
		display: inline-block;
		vertical-align: middle;
	}

	#header .rtMenu .cafe .ico {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
	}

	#header .rtMenu .cafe .ico img {
		width: 100%;
	}

	#header .rtMenu .cafe .text {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		color: #fff;
		font-family: 'lato';
		margin-left: 5px;
		display: none;
	}

	#header .rtMenu .allMenu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 30px;
		display: none;
	}

	#header .rtMenu .moMenu {
		display: inline-block;
		vertical-align: middle;
		margin-left: 0.5em;
		width: 20px;
	}

	#header .rtMenu .moMenu img {
		width: 100%;
	}









	#footer {
		width: 100%;
		background: #333333;
		padding: 3EM 0px;
	}

	#footer .inner {
		width: 94%;
		margin: 0 auto;
	}

	#footer .inner .topLine {
		width: 100%;
		display: inline-block;
	}

	#footer .inner .topLine .footLogo {
		width: 290px;
		display: block;
		margin: 0 auto;
	}

	#footer .inner .topLine .footLogo img {
		width: 100%;
	}

	#footer .inner .topLine .snsList {
		display: inline-block;
		clear: both;
		width: 100%;
		text-align: center;
		margin-top: 15px;
	}

	#footer .inner .topLine .snsList li {
		width: 20px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
	}

	#footer .inner .topLine .snsList li:first-child {
		margin-left: 0px;
	}

	#footer .inner .topLine .snsList li img {
		width: 100%;
	}

	#footer .inner .footMenu {
		width: 100%;
		margin-top: 40px;
		text-align: center;
	}

	#footer .inner .footMenu li {
		display: inline-block;
		padding: 0px 7px;
		border-right: 1px solid #777;
		color: #777;
		font-size: 13px;
		line-height: 13px;
	}

	#footer .inner .footMenu li a {
		color: #777;
	}

	#footer .inner .footMenu li:last-child {
		border-right: 0px solid #777;
	}

	#footer .inner .footMenu li:first-child {
		padding: 0px 10px 0px 0px;
	}

	#footer .inner .copy {
		width: 100%;
		margin-top: 20px;
		font-size: 13px;
		color: #666666;
		line-height: 22px;
		text-align: center;
		word-break: keep-all;
	}

	#footer .inner .copy br {
		display: none;
	}




}