#main{
	width:100%;
}

#main .visual{
	width:100%;
	position:relative;
}

#main .visual .mainVisual{
	width:100%;
}

#main .visual .mainVisual li{
	width:100%;
	position:relative;
}

#main .visual .mainVisual li .bg{
	width:100%;
}

#main .visual .mainVisual li .bg img{
	width:100%;
}

#main .visual .mainVisual li .cont{
	width:100%;
	position:absolute;
	padding:0px 50px;
	top:50%;
	transform:translateY(-50%);
}

#main .visual .mainVisual li .cont .title{
	font-size:80px;
	color:#fff;
	font-weighT:bold;
	font-family:'lato','nanumsquare';
}


#main .visual .mainVisual li .cont .substance{
	font-size:36px;
	color:#fff;
	margin-top:30px;
	font-weight:300;
}

#main .visual .mainVisual li .cont .substance span{
	font-weight:bold;
}



#main .visual .mainVisual .slick-prev{
	background:url(../images/mainVisual_prev.png) no-repeat;
	width:27px;
	height:53px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:50px;
	top:73%;
	z-index:2;
	cursor:pointer;
}


#main .visual .mainVisual .slick-next{
	background:url(../images/mainVisual_next.png) no-repeat;
	width:27px;
	height:53px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:100px;
	top:73%;
	cursor:pointer;
}





#main .mainTitle{
	width:100%;
	text-align:Center;
	font-size:36px;
	color:#222;
	font-weight:bold;
}





#main .golf{
	width:100%;
	margin-top:100px;
	text-align:Center;
	position:relative;
}

#main .golf .golfTab{
	width:100%;
	margin-top:50px;
	text-align:Center;
}


#main .golf .golfTab li{
	width:130px;
	display:inline-block;
	background:#f7f7f7;
	text-align:center;
	padding:15px 0px;
	font-size:18px;
	color:#222;
	border-radius:50px;
	cursor:pointer;
}


#main .golf .more{
	text-align:Center;
	font-size:18px;
	color:#004f86;
	padding:0px 5px 2px;
	border-bottom:1px solid #004f86;
	display:inline-block;
	margin-top:50px;
}


#main .golf .more a{
	color:#004f86;
}

#main .golf .more span{
	margin-left:5px;
}

#main .golf .golfTitles {
	cursor: pointer;
	background-color: #f7f7f7;
	color: #222;
}
#main .golf .golfTitles.on {
	background-color: #004f86;
	color: #fff;
}

#main .golf .golfCont{
	position:absolute;	
	widtH:100%;
	bottom:0;
	margin-top:50px;
	display: none;	
}	
#main .golf .golfCont.on {	
	position: relative;
	display: block;	
	animation-duration: 1s;
  animation-name: fadeIn;
}	

@keyframes fadeIn {
  from {		
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#main .golf .golfCont .slick-list .slick-track .slick-slide li .thum{
	border-radius: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	aspect-ratio: 1 / 1;
}

/* .slick-slide > div {
	display: flex;
	justify-content: center;
}
.slick-slide > div > li{
	display: flex !important;
	justify-content: center;
} */

#main .golf .golfVisual{
	width:100%;
	position:relative;
}

#main .golf .golfVisual .slick-dots{
	width:100%;
	position:absolute;
	margin-top:30px;
	text-align:center;
}

#main .golf .golfVisual .slick-dots li{
	width:8px;
	height:8px;
	margin:0 3px;
	outline:0;
	text-indent:-9999px;
	background:#fff;
	border:1px solid #004f86;
	border-radius:10px;
	display:inline-block;
	vertical-align:middle;
}

#main .golf .golfVisual .slick-dots li:hover,
#main .golf .golfVisual .slick-dots li.slick-active{
	width:10px;
	height:10px;
	border:2px solid #004f86;
}



#main .golf .golfVisual .slick-prev{
	background:url(../images/golfVisual_prev.png) no-repeat;
	width:20px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:50%;
	margin-left:-270px;
	top:35%;
	transform:translateY(-50%);
	z-index:2;
	cursor:pointer;
}


#main .golf .golfVisual .slick-next{
	background:url(../images/golfVisual_Next.png) no-repeat;
	width:20px;
	height:38px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:50%;
	margin-right:-270px;
	transform:translateY(-50%);
	top:35%;
	cursor:pointer;
}


#main .golf .golfVisual li{
	/* margin:0px 15px; */
}


#main .golf .golfVisual li .thum{
	width:100%;
	filter:grayscale(1);
	opacity:0.5;
	transform:scale(0.8);
}


#main .golf .golfVisual .thum img{
	width:100%;
	object-fit: cover;
	object-position: center center;
}

#main .golf .golfVisual li .textCont{
	width:100%;
	margin-top:40px;
	display:none;
}

#main .golf .golfVisual li .textCont .po{
	font-size:16px;
	color:#777777;
	text-align:Center;
	font-family:'lato','nanumsquare';
}

#main .golf .golfVisual li .textCont .subj{
	font-size:20px;
	color:#222;
	text-align:Center;
	font-family:'lato','nanumsquare';
	font-weight:bold;
	margin-top:10px;
}


#main .golf .golfVisual li .textCont .subs{
	font-size:18px;
	color:#444;
	text-align:Center;
	margin-top:30px;
	line-height:26px;
	word-break:keep-all;
}


#main .golf .golfVisual li .textCont .arl{
	font-size:16px;
	color:#004f86;
	text-align:Center;
	margin-top:15px;
	line-height:24px;
}



#main .golf .golfVisual .slick-center .thum{
	width:100%;
	filter:none;
	opacity:1;
	transform:scale(1);	
}


#main .golf .golfVisual .slick-center .textCont{
	display:block;
}






#main .package{
	width:100%;
	background:#f7f7f7;
	padding:100px 0px;
	margin-top:100px;
}

#main .package .inner{
	max-width:1180px;
	margin:0 auto;
}


#main .package .inner .packageList{
	display:flex;
  justify-content: space-between;
	width:100%;
	margin-top:50px;
}

#main .package .inner .packageList li{
	flex: 1 1; 
	margin-left: 20px;
}

#main .package .inner .packageList li:first-child{
	margin-left:0px;
}

#main .package .inner .packageList li .thum{
	width:100%;
  height: auto;
}

#main .package .inner .packageList li .thum img{
  object-fit: cover
}

#main .package .inner .packageList li .bat{
	width:60px;
	position:relative;
	margin-top:-12px;
	background:#004f86;
	color:#fff;
	text-align:center;
	margin-left:20px;
	padding:5px 0px;
	font-family:'lato';
	font-size:13px;
	z-index:0;
}

#main .package .inner .packageList li .textArea{
	width:100%;
	background:#fff;
	padding:30px 20px;
	box-sizing:border-box;
	margin-top:-12px;
}

#main .package .inner .packageList li .textArea .subj{
	font-size:18px;
	color:#222;
	font-weight:bold;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

#main .package .inner .packageList li .textArea .subs{
	font-size:15px;
	color:#777777;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	margin-top:8px;
  max-width: 240px;
}

#main .package .inner .packageList li .textArea .price{
	font-size:18px;
	color:#d30000;
	width:100%;
	margin-top:30px;
	font-family:'lato','nanumsquare';
}




#main .promotion{
	max-width:1180px;
	margin:100px auto 0;
}

#main .promotion .promotionList{
	width:100%;
	display:inline-block;
	position:relative;
	margin-top:50px;
}

#main .promotion .promotionList dd{
	position:relative;
	display:inline-block;
	transform:scale(0.9);
}

#main .promotion .promotionList .slick-center{
	transform:scale(1.05);
}

#main .promotion .promotionList .slick-center .thum{
	transform:scale(1.05);
}


#main .promotion .promotionList dd .thum{
	width:100%;
}

#main .promotion .promotionList dd .thum img{
	width:100%;
}

#main .promotion .promotionList dd .textArea{
	width:100%;
	position:absolute;
	right:30px;
	bottom:30px;
	text-align:right;
	z-index:1;
}

#main .promotion .promotionList dd .textArea .subs{
	font-size:16px;
	color:#fff;
	opacity:0.8;
}

#main .promotion .promotionList dd .textArea .subj{
	font-size:24px;
	color:#fff;
	font-weight:bold;
	margin-top:10px;
}

#main .promotion .promotionList dd .textArea .more{
	font-size:16px;
	color:#fff;
	margin-top:20px;
}

#main .promotion .promotionList dd .textArea .more a{
	color:#fff;
}

#main .promotion .promotionList dd .textArea .moreList{
	width:100%;
	text-align:right;
	margin-top:20px;
}

#main .promotion .promotionList dd .textArea .moreList li{
	font-size:16px;
	color:#fff;
	display:inline-block;
	padding:0px 10px;
	border-left:1px solid #fff;
	line-height:16px;
}


#main .promotion .promotionList dd .textArea .moreList li:first-child{
	border-left:0px solid #fff;
}

#main .promotion .promotionList dd .textArea .moreList li:last-child{
	padding:0px 0px 0px 10px;
}


#main .promotion .promotionList dd .textArea .moreList li a{
	color:#fff;
}









#main .news{
	max-width:1180px;
	margin:100px auto;
}

#main .news .titleLine{
	width:100%;
	display:inline-block;
}

#main .news .titleLine .titleArea{
	display:inline-block;
}

#main .news .titleLine .titleArea .en{
	font-size:20px;
	color:#777;
}

#main .news .titleLine .titleArea .title{
	font-size:36px;
	margin-top:10px;
	color:#222222;
	font-weight:bold;
}

#main .news .titleLine .more{
	display:inline-block;
	float:right;
	margin-top:45px;
}

#main .news .titleLine .more .text{
	display:inline-block;
	vertical-align:middle;
	font-size:18px;
	color:#004f86
}

#main .news .titleLine .more .ico{
	width:28px;
	display:Inline-block;
	vertical-align:middle;
}

#main .news .titleLine .more .ico img{
	width:100%;
}


#main .news .newsCont{
	width:100%;
	display:inline-block;
	margin-top:30px;
}

#main .news .newsCont .notice{
	width:49%;
	display:inline-block;
	float:left;
	position:relative;
}

#main .news .newsCont .notice .bg{
	width:100%;
}

#main .news .newsCont .notice .bg img{
	width:100%;
}

#main .news .newsCont .cont{
	width:60%;
	position:absolute;
	padding:30px;
	box-sizing:border-box;
	left:0;
	bottom:0;
	background:#fff;
}

#main .news .newsCont .cont .bat{
	width:50px;
	background:#004f86;
	color:#fff;
	text-align:center;
	padding:6px 0px;
	font-size:13px;
	border-radius:50px;
}

#main .news .newsCont .cont .subject{
	font-size:18px;
	color:#222;
	margin-top:10px;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	font-weight:bold;
}

#main .news .newsCont .cont .substance{
	font-size:15px;
	color:#444;
	margin-top:15px;
	width:100%;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}


#main .news .newsCont .cont .date{
	font-size:15px;
	color:#999;
	margin-top:30px;
	font-family:'lato'
}

#main .news .newsCont .newsList{
	width:49%;
	display:inline-block;
	margin-left:2%;
}

#main .news .newsCont .newsList li{
	width:48.5%;
	display:inline-block;
	margin-left:3%;
	float:left;
	border:1px solid #e5e5e5;
	box-sizing:border-box;
	margin-top:20px;
	padding:30px;
}

#main .news .newsCont .newsList li:nth-child(2n+1){
	margin-left:0%;
}

#main .news .newsCont .newsList li:nth-child(2),
#main .news .newsCont .newsList li:nth-child(3){
	background:#f7f7f7;
}


#main .news .newsCont .newsList li:nth-child(1),
#main .news .newsCont .newsList li:nth-child(2){
	margin-top:0;
}


#main .news .newsCont .newsList li .subject{
	font-size:18px;
	width:100%;
	color:#222;
	line-height:28px;
	height:56px;
	overflow:Hidden;
	word-break:keep-all;
}

#main .news .newsCont .newsList li .date{
	font-size:15px;
	width:100%;
	color:#777;
	margin-top:54px;
}

.kakaoChat{
	position: fixed;
	right: 0%;
	bottom: 0%;
}

.kakaoChat > a > img{
	width: 70%;
}

.business-gallery {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
@media (min-width: 1020px) {
  .business-gallery {
    padding: 0 0px;
  }
}
.business-gallery .write-btn {
  width: 150px;
  justify-content: end;
  padding: 13px;
}
.business-gallery .btn-area {
  display: flex;
  justify-items: start;
  padding-top: 20px;
}
.business-gallery .btn-area .divide{
  padding: 0px 10px;
}
.business-gallery .input-area{
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.business-gallery .quill {
  height: 600px;
  margin-bottom: 40px;
  font-size: 35px;
}
.business-gallery ul {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
}
.business-gallery ul li {
  position: relative;
  margin: 8px;
  flex: 0 0 auto;
  width: calc(50% - 16px);
  border: 1px solid #F5F5F5;
}
.business-gallery ul li::after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
@media (min-width: 1020px) {
  .business-gallery ul li {
    width: calc(25% - 16px);
  }
}
@media (min-width: 1920px) {
  .business-gallery ul li {
    width: 302px;
    height: 302px;
  }
}
.business-gallery ul li div {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}
.business-gallery ul li div span {
  display: block;
  width: 100%;
  text-align: start;
  color: #000000;
  font-size: 0.688em;
  line-height: 1.2;
  padding: 10px;
}
.business-gallery ul li div a h3 {
  display: none;
}

@media (min-width: 1020px) {
  .business-gallery ul li div span {
    font-size: 16px;
  }
}

.business-gallery ul li div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 35vw;
}

@media (min-width: 1020px) {
  .business-gallery ul li div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 13vw;
  }
}

.business-gallery ul li div h3 {
  display: none;
}

ul.paging { 
  width:100%;
  height: 16px;
	text-align:center;
	margin:50px 0px 200px 0px;
}

ul.paging li { 
  width: auto;
	display:inline-block;
	margin:0px 10px;
}

ul.paging li a {
  font-family: 'Lato';
  font-size: 16px;
	color:#777;
}

ul.paging .on a {
  font-weight: bold;
  color: #222;
  text-decoration: underline;
}

ul .paging li img{
	width:100%;
}

@media screen and (max-width: 768px) {
ul.paging { 
  width: 100%;
  height: 3.515625vw;
  margin:10.4166vw 0px 19.53125vw 0px;
}

ul.paging li { 
  margin:0px 2.62760vw;
}


ul.paging li a {
  font-size: 3.3854vw;
}
}

.business-gallery .detail-title-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0px;
}

.detail-title-box .detail-writer{
  font-size: 26px;
  font-weight: 400;
}

.detail-title-box .detail-writer span{
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .detail-title-box{
    display: block;
    margin: 40px 0px;
  }

  .detail-title-box .detail-writer{
    display: flex;
    font-size: 20px;
    justify-content: end;
  }
  
  .detail-title-box .detail-writer span{
    font-size: 14px;
  }

  .detail-title-box .detail-writer li {
    display: none;
  }

  .detail-content-box .board-content {
    padding: 1.302vw;
  }

  .detail-content-box .board-content p{
    font-size: 13px;
    line-height: 2;
  }

  .detail-content-box .board-content p img{
    width: 100%;
  }

  .detail-content-box .board-comment .title{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .name{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .func{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .comment-write {
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .comment-write p{
    font-family: "NanumSquare_acR";
    font-size: 12px;
    line-height: 2;
  }

  .detail-content-box .board-btn .btn-box button{
    text-align: center;
    font-size: 11px;
    justify-content: center;
    align-items: center;
  }

  .detail-content-box .board-btn .btn-box button svg{
    width: 12px;
    height: 12px;
    margin: 0px 3px 0px 3px;
  }
}


.board-gallery-list ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}
.board-gallery-list ul li {
  flex: 0 0 100%;
  margin-bottom: 30px;
}
.board-gallery-list ul li a {
  display: block;
}
.board-gallery-list ul li a .img {
  height: 210px;
  margin-bottom: 20px;
}
.board-gallery-list ul li a .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.board-gallery-list ul li a .text {
  max-width: 380px;
}

.board-gallery-list ul li a .text .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  margin-bottom: 6px;
  font-weight: 700;
}
.board-gallery-list ul li a .text .info {
  display: flex;
  font-size: 13px;
  color: #777;
}
.board-gallery-list ul li a .text .info .date {
  color: inherit;
}
.board-gallery-list ul li a .text .info .date::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #777;
  margin: 0 10px;
}
.board-gallery-list ul li a .text .info .view {
  color: inherit;
}

@media (min-width: 768px) and (max-width: 1179px) {
  .board-gallery-list ul {
    margin: -10px;
  }
  .board-gallery-list ul li {
    flex: 0 0 calc(50% - 20px);
    margin: 10px 10px 30px 10px;
  }
}

@media (min-width: 1180px) {
  .board-gallery-list {
    border-top: 1px solid #222;
  }
  .board-gallery-list ul {
    margin: -10px;
    padding-top: 65px;
  }
  .board-gallery-list ul li {
    flex: 0 0 calc(33.33% - 20px);
    margin: 10px 10px 55px 10px;
  }
  .board-gallery-list ul li a .text .title {
    font-size: 18px;
  }
  .board-gallery-list ul li a .text .info {
    font-size: 16px;
  }

}

.board .board-paginate {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.board .board-paginate a, .board-paginate div, .board .board-paginate span {
  display: inline-flex;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #777777;
  justify-content: center;
}
.board .board-paginate .prev {
  text-indent: -9999999px;
  overflow: hidden;
  background: url(../images/common/page_prev.png) center no-repeat;
}
.board .board-paginate .next {
  text-indent: -9999999px;
  overflow: hidden;
  background: url(../images/common/page_next.png) center no-repeat;
}
.board .board-paginate .current div {
  color: #222;
  font-weight: 900;
  text-decoration: underline;
}
.board .board-paginate ul {
  display: inline-flex;
  margin: 0 30px;
}

.quill {
  margin-top: 20px;
  margin-bottom: 50px;
  height: 500px;
  font-size: 35px;
}
.ql-container{
  font-size: 16px;
}
.ql-container .ql-editor {
  box-sizing: border-box;
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-container p {
  box-sizing: border-box;
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-toolbar.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 10px 10px 0 0 ;
}

.ql-container.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 0 0 10px 10px ;
}

@media (min-width: 1020px) {
  .button-primary.size-l {
    padding: 0;
    height: 70px;
    line-height: 70px;
  }
  .button-primary.size-s {
    padding: 0 15px;
    height: 50px;
  }
}

.btn-area button {
  display: inline-block;
  width: 90px;
  line-height: 36px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 15px;
  border-color: #f4f4f4;
  background: #f4f4f4;
  text-align: center;
  margin: 0 0px;
}

.button-primary {
  padding: 12px 8px;
  text-align: center;
  color: #fff;
  background: #623189;
  cursor: pointer;
}

.detail-content-box {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.detail-content-box .board-content{
  height: auto;
  padding: 30px 0px 50px 0px;
  line-height: 1.5;
  text-underline-offset : 4px;
  font-size: 15px;
}

.detail-content-box .board-file{
  height: auto;
}
