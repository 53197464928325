@charset 'UTF-8';
/* Slider */

.slick-current,
.slick-active,
.slick-slide,
.slick-track,
.slick-slide
{
    outline: none;
}

.slick-list a
{
    outline: none;
}


