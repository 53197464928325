#admin {width: 100%;}
#admin .admin--wrapper {max-width: 850px; margin: 0 auto; height: 34vh;}
#admin .header {display: flex; justify-content: space-between; margin-top: 15rem;}
#admin .logout-btn {padding: 0 1rem; border-radius: .5rem; cursor: pointer;}
#admin .body {padding:2rem 0}
#admin .gotoWrite {display: flex; align-items: center; padding: 0 1rem; border-radius: .5rem; cursor: pointer;}
#admin .body .icon--container {display: flex; padding-right: 0.5rem;}
#admin .gotoWrite:hover,
#admin .gotoWrite:focus {color: #005f86; animation: blink-effect 2s ease-in-out infinite;}
#admin .body .edit-icon {width: 1.5rem; }

@keyframes blink-effect {
  0% {opacity: 1;}
  50%{opacity: .3;}
  100%{opacity: 1;}
}