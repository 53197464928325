/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


/*
.slick-prev{
	background:url(../images/slidesPrev.png) no-repeat;
	width:16px;
	height:31px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	left:-40px;
	margin-top:5px;
}


.slick-next{
	background:url(../images/slidesNext.png) no-repeat;
	width:16px;
	height:31px;
	border:0;
	text-indent:-9999px;
	position:absolute;
	right:-40px;
	margin-top:-35px;
}

.slick-dots{
	width:100%;
	position:absolute;
	margin-top:-30px;
	text-align:center;
}

.slick-dots li{
	width:10px;
	height:10px;
	margin:0 3px;
	outline:0;
	text-indent:-9999px;
	background:#fff;
	border-radius:10px;
	display:inline-block;
}

.slick-dots li:hover,
.slick-dots li.slick-active{
	background:#000;
}

*/


















